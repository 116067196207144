import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage1Avaleht from "./pages/Webpage1Avaleht";

var baseURL = 'https://helenimaja.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiMmZjM2EzZmU1MGFmZWQzNzRjYjFiYzk1NmMzY2I0NmE2ZjcxNTMzYzMxNGI0NWNhYzczYmQ0ZDA2NzYyNzcxZDg3NmM5OTc0M2I0MTM0YWQiLCJpYXQiOjE3MjgwNDM5MTEuMjA3OTY1LCJuYmYiOjE3MjgwNDM5MTEuMjA3OTY5LCJleHAiOjE3MjgxMzAzMTEuMjA0MTMsInN1YiI6IjY2Iiwic2NvcGVzIjpbXX0.pZMkTqTJgIMTrM-vcZvjUCtKhLDUjBgA9v8Kwhi2epqOEmJ1HJd130FZKJgw1XdCEt8s3G4yDBMt4-mgh7enb8-ndSg6lXnMwgQREe3noWZV8n2P6JvPfrQfLTWRTUCSatlRG6YzOL8ycStWJDEmVyFX5fhNnDvFFpjoIjZXeTmgkmcn1lwKZ7cMUJOVJj_f_MYVmA2KXQJq5946eNjvxoEbXcvIMqNQgE0-jaMsFTvSEj8DGqwFMITPZ8wcpyViDVaTzD8RPPIkaE7G9YZtUF1FjFSHtuGnNdmTlruwaw2AhxhaSlv6uwW32YWJyjZ2gAC6SjDlwLS4ys13qqro7fGBUNnoPjrF22QXYwnFSIN6adrmhGveLTb2fmuQlYZ4QnVSbwA-FsHgLnnujDOZMBBHlqWI31rni3UGt16JzxKgtY45NYufBIjXH9-8XhetGgvZUIkv2eDoV6_8B2b_WZOMk7dIJP64PqSq5dZ9FudXOQv9nZE6HwmwU-XvYFKbMxo8we2xGKYKYa2uegDJ_e1z0Ln9iACse6dAQXwmcVPAZ1ugK-g06ct7jJBbjxRRMWoQPcvby96myiLdg4mYPesWfmci9dYpF-zkiS02Uie82aInU9sRQbVmaLBTRqMXv-Hog7iTSKXE9nW_zUAkZCSGpberrHZmE9p3Kaerrmc',
    'Access-api-key': "eyJpdiI6IndYR1Y1ZnJ4REE3cFJ6TE53UGlndlE9PSIsInZhbHVlIjoiWnZDRk5mcnExU2JHVW9peUtXUWxlQT09IiwibWFjIjoiZTYzNmExMWQ2NDdmYTRlZTcwNjUwMTIxYmY5NDI0NGQ2M2JmNjFlNGU4NWYwZDI2MzhhODMwYTRmYWE3NTlmMiIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IlhUUU02ZDVkSDVpRGZ4RXAvSzlsR2c9PSIsInZhbHVlIjoiV2dxUmtnT0Yxa2RzVWgza0VYNGJoQT09IiwibWFjIjoiNjg5Yzk5MGQ3Njg1M2FjZmJmYWMwOWIzNTE3NzViNTAxMWNhZTViOGFjMjMzY2VmMTEzN2EyNTk0ZmJkNThhYiIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: ""
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage1Avaleht />} exact />;
                                                                
                                                                <Route path="*" element={<Webpage1Avaleht />} exact />;
                                        </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}